import {
  getStoreCode,
  getCountryCode,
  getKeyStoreDataversion,
  getDefaultLanguage
} from "../../template/state/modules/regionalStore";
import clientConfig from "../../template/clientConfig";
import { getFeaturesFromWindowManifest } from "./features";
import get from "lodash/get";

const TOKEN_TYPES = "id_token token";
const IDENTITY_SCOPES = "openid sensitive profile";

const isInterstitialPageEnabled = browseCountry => {
  const featuresFromManifest = getFeaturesFromWindowManifest();
  const interstitialPageFeature = get(
    featuresFromManifest,
    "features[login-interstitial-page]"
  );
  if (
    interstitialPageFeature === true ||
    (Array.isArray(interstitialPageFeature) &&
      interstitialPageFeature.some(
        country => country.toLowerCase() === browseCountry.toLowerCase()
      ))
  ) {
    return true;
  }
  return false;
};

const getRedirectUri = (state, config) => {
  const browseCountry = getCountryCode(state);
  if (isInterstitialPageEnabled(browseCountry)) {
    return `${window.location.origin}${
      config.IDENTITY_SDK_REDIRECT_PATH
    }?lang=${getDefaultLanguage(state)}&store=${getStoreCode(
      state
    )}&countryCode=${browseCountry}&keyStoreDataversion=${getKeyStoreDataversion(
      state
    )}&finalredirecturi=${encodeURIComponent(window.location.href)}`;
  }
  return window.location.href;
};

export const initialise = store =>
  new Promise(resolve => {
    const config = clientConfig.get();
    const state = store.getState();

    const identityConfig = {
      lang: getDefaultLanguage(state),
      regionalIsoCode: getDefaultLanguage(state),
      clientId: config.IDENTITY_SDK_CLIENT_ID,
      customerApiUrl: config.IDENTITY_SDK_CUSTOMER_API_URL,
      authenticationHost: config.IDENTITY_SDK_AUTH_HOST,
      identityOrigin: config.IDENTITY_SDK_IDENTITY_ORIGIN,
      responseMode: "json",
      store: getStoreCode(state),
      countryCode: getCountryCode(state),
      keyStoreDataversion: getKeyStoreDataversion(state),
      types: TOKEN_TYPES,
      scope: IDENTITY_SCOPES,
      redirect_uri: getRedirectUri(state, config)
    };
    requirejs(["asos.identity.sdk.config"], configurator => {
      configurator.setConfig(identityConfig);
      requirejs(["asos.identity.web.clientsdk"], sdk => {
        sdk.getAuthHeader = async () => `Bearer ${await sdk.getAccessToken()}`;
        resolve(sdk);
      });
    });
  });
