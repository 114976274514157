import { sdk } from "@asosteam/asos-web-audience-sdk";
import { noticeError } from "@src/helpers/monitoring";

import {
  asosGlobalNamespace,
  sdkGlobalNamespace
} from "../../template/constants";

export const initialise = () => {
  const {
    getAccessToken,
    customer: { customerGuid }
  } = window[asosGlobalNamespace][sdkGlobalNamespace].identity;

  const logger = {
    error: noticeError
  };

  const config = { getAccessToken, getCustomerGuid: customerGuid, logger };

  return sdk(config);
};
